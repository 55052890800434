import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dashboard" }
const _hoisted_2 = { class: "dashboard-cards" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dashboard_card = _resolveComponent("dashboard-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_dashboard_card, {
        "data-sel": "accounts-card",
        iconClass: "fas fa-building",
        isAddable: true,
        title: _ctx.$t('dashboard-card_accounts_title'),
        onNavigateAdd: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateTo('account-new'))),
        onNavigateList: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigateTo('accounts')))
      }, null, 8, ["title"]),
      _createVNode(_component_dashboard_card, {
        "data-sel": "contacts-card",
        iconClass: "fas fa-address-book",
        isAddable: true,
        title: _ctx.$t('dashboard-card_contacts_title'),
        onNavigateAdd: _cache[2] || (_cache[2] = ($event: any) => (_ctx.navigateTo('contact-new'))),
        onNavigateList: _cache[3] || (_cache[3] = ($event: any) => (_ctx.navigateTo('contacts')))
      }, null, 8, ["title"]),
      _createVNode(_component_dashboard_card, {
        "data-sel": "opportunities-card",
        iconClass: "fas fa-chart-line",
        isAddable: true,
        title: _ctx.$t('dashboard-card_opportunities_title'),
        onNavigateAdd: _cache[4] || (_cache[4] = ($event: any) => (_ctx.navigateTo('opportunity-new'))),
        onNavigateList: _cache[5] || (_cache[5] = ($event: any) => (_ctx.navigateTo('opportunities')))
      }, null, 8, ["title"]),
      _createVNode(_component_dashboard_card, {
        "data-sel": "quotes-card",
        iconClass: "fas fa-file-invoice-dollar",
        isAddable: true,
        title: _ctx.$t('dashboard-card_quotes_title'),
        onNavigateAdd: _cache[6] || (_cache[6] = ($event: any) => (_ctx.navigateTo('quote-new'))),
        onNavigateList: _cache[7] || (_cache[7] = ($event: any) => (_ctx.navigateTo('quotes')))
      }, null, 8, ["title"]),
      _createVNode(_component_dashboard_card, {
        "data-sel": "sales-orders-card",
        iconClass: "fas fa-receipt",
        isAddable: true,
        title: _ctx.$t('dashboard-card_sales-orders_title'),
        onNavigateAdd: _cache[8] || (_cache[8] = ($event: any) => (_ctx.navigateTo('sales-orders-new'))),
        onNavigateList: _cache[9] || (_cache[9] = ($event: any) => (_ctx.navigateTo('sales-orders')))
      }, null, 8, ["title"])
    ])
  ]))
}
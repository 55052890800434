// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                      
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'card',
  props: {
    isAddable: { type: Boolean, default: false },
    iconClass: { type: String, default: null },
    title: { type: String, default: null }
  },
  emits: [ 'navigate-add', 'navigate-list' ]
})

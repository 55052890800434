// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import { defineComponent } from 'vue'
import DashboardCard from '@/pages/dashboard/dashboard-card/index.vue'

export default defineComponent({
  name: 'dashboard',
  components: {
    DashboardCard
  },
  methods: {
    navigateTo(name) {
      this.$router.push({ name: name })
    }
  }
})
